<template>
  <div>
    <v-row class="pa-0">
      <v-col cols="11" lg="7" xl="8" class="mx-auto hidden-sm-and-down text-center hidden-md-and-down">
        <div class="text-center pt-16">
          <lottie-player src="/lotties/bot.json" background="transparent" speed="1.2" class="mx-auto bot-figure" loop autoplay></lottie-player>
        </div>
      </v-col>
      <v-col cols="11" lg="5" xl="4" class="mx-auto pr-lg-10">
        <v-card class="rounded-xl my-8" style="height: 800px;">
          <div class="px-16 text-center py-16">
            <v-img
              width="400"
              src="/logo.png"
              class="my-16 mx-auto"
            />
          </div>
          <!-- title -->
          <v-card-text>
            <p class="text-2xl font-weight-bold text--primary mb-2">
              Masiya Chat Bot
            </p>
          </v-card-text>

          <!-- login form -->
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="email"
                outlined
                label="Email or Username"
                :error="(!!(errors && errors.email))"
                :error-messages="(errors && errors.email ? errors.email: null)"
                :hide-details="!(errors && errors.email)"
                class="mb-3"
                @keyup.enter="submit"
              ></v-text-field>

              <v-text-field
                v-model="password"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Password"
                :error="(!!(errors && errors.password))"
                :error-messages="(errors && errors.password ? errors.password: null)"
                :hide-details="!(errors && errors.password)"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
                @keyup.enter="submit"
              ></v-text-field>

              <v-btn
                block
                large
                color="primary"
                class="mt-6"
                :loading="loading"
                :disabled="loading"
                @click.prevent="submit"
              >
                Login
              </v-btn>
              <p class="pt-16 text-center">
                Powered By <span class="font-weight-bold">Masiya Group</span>
              </p>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {mdiEyeOutline, mdiEyeOffOutline} from '@mdi/js'

export default {
  data() {
    return {
      isPasswordVisible: false,
      loading: false,
      email: '',
      password: '',
      errors: null,
      autologin: false,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  created() {
  },
  methods: {
    submit() {
      this.errors = true
      this.$store.state.loading = true
      this.$http({
        method: 'post',
        url: '/v1/auth/login',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
        data: {
          email: this.email,
          password: this.password,
        },
      }).then(response => {
        this.$store.commit('SetUser', response.data.user)
        this.$store.commit('SetAccess', response.data.access)
        this.$router.push({name: 'bots'})
      }).catch(err => {
        this.errors = err.response.data
      }).finally(() => {
        this.$store.state.loading = false
      })
    },
  },
}
</script>

<style lang="scss">
.bot-figure {
  width: 750px;
  height: 500px;
  position: absolute;
  left: 0;
  right: 40%;
  top: 0;
  bottom: 0;
  margin: auto;
}
@media only screen and (max-width: 1880px) {
  .bot-figure {
    width: 600px;
  }
}
@media only screen and (max-width: 1392px) {
  .bot-figure {
    width: 450px;
  }
}
</style>
